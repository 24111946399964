import { CommonModule } from '@angular/common';
import { booleanAttribute, Component, computed, inject, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

type SelectOptions<T> = {
  key: string;
  value: T;
  group?: string;
}[];

@Component({
  selector: 'dpqa-multi-select',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslatePipe, NgSelectModule],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.less',
})
export class MultiSelectComponent<T> {
  private translate = inject(TranslateService);

  public value = input<T[]>();
  public options = input.required<SelectOptions<T>>();
  public selectId = input.required<string>();
  public maxTagCount = input(1);
  public placeholder = input<string>(this.translate.instant('FILTER_BAR.SELECT_PLACEHOLDER'));
  public disabled = input(false);
  public searchable = input(false, { transform: booleanAttribute });

  public updateValue = output<T[]>();

  public maxTagMessage = computed(() => {
    const currentValue = this.value();
    const valueLength = Array.isArray(currentValue) ? currentValue.length : 0;

    if (valueLength) {
      return this.translate.instant('FILTER_BAR.SELECT_MAX_TAG_PLACEHOLDER', { count: valueLength });
    }

    return null;
  });

  handleValueChange(e: T[]) {
    this.updateValue.emit(e);
  }
}
