<div class="filter-bar">
  <section class="filters">
    <div class="amount-of-slides">
      <h1>{{ numberOfSlides() }}</h1>
    </div>

    <div class="sorting-direction">
      <h1>{{ "COMMON.SLIDES" | translate }}</h1>

      <a href="#" (click)="toggleSorting($event)">
        <dpqa-icon iconName="filter" iconSize="1.8" />
        <span>{{ "FILTER_BAR." + sortDirection() | uppercase | translate }}</span>
        <dpqa-icon [iconName]="sortDirection() === 'asc' ? 'chevron-up' : 'chevron-down'" iconSize="1.6" />
      </a>
    </div>

    <nz-divider nzType="vertical" />

    <div class="date-range">
      <h2>{{ "FILTER_BAR.DATA_RANGE" | translate }}</h2>
      <dpqa-date-range-picker
        [selectedDateRange]="selectedDateRange()"
        (selectedDateRangeChange)="selectedDateRangeChanged($event)"
        [disabledDates]="disabledDates"
        [presetRanges]="presetRanges"
      />
    </div>

    <nz-divider nzType="vertical" />

    <div>
      <h2>{{ "FILTER_BAR.SLIDE_STATUS" | translate }}</h2>
      <dpqa-status-picker [status]="selectedStatus()" (statusChange)="updateStatus($event)" />
    </div>

    <div>
      <h2>{{ "FILTER_BAR.TAGS.TITLE" | translate }}</h2>
      <dpqa-multi-select
        [value]="selectedTag()"
        (updateValue)="handleUpdateSelectedTag($event)"
        [options]="selectedStatus() === 'in-queue' ? tagOptionsInQueue : tagOptionsVerified"
        selectId="tag"
        [placeholder]="'FILTER_BAR.ALL' | translate"
        [disabled]="selectedStatus() === 'all'"
      />
    </div>

    <nz-divider nzType="vertical" />

    <div>
      <h2>{{ "FILTER_BAR.SCANNER" | translate }}</h2>
      <dpqa-select
        [value]="selectedScannerType()"
        (updateValue)="handleUpdateSelectedScannerType($event)"
        [options]="scannerTypeOptions()"
        selectId="scannerType"
        [placeholder]="'FILTER_BAR.ALL' | translate"
      />
    </div>

    <div>
      <h2>{{ "FILTER_BAR.RACK" | translate }}</h2>
      @if (rackOptions(); as options) {
        <dpqa-multi-select
          [value]="selectedRack()"
          (updateValue)="handleUpdateSelectedRack($event)"
          [options]="options"
          selectId="rack"
          [placeholder]="'FILTER_BAR.ALL' | translate"
          [disabled]="selectedScannerType() == undefined"
        />
      }
    </div>

    <nz-divider nzType="vertical" />

    <div>
      <h2>{{ "COMMON.STAIN" | translate }}</h2>
      <dpqa-multi-select
        [value]="selectedStaining()"
        (updateValue)="handleUpdateStaining($event)"
        [options]="stainOptions()"
        selectId="staining"
        [placeholder]="'FILTER_BAR.ALL' | translate"
        searchable
      />
    </div>

    @if (customField1DisplayName(); as name) {
      <nz-divider nzType="vertical" />

      <div>
        <h2>{{ name }}</h2>
        <dpqa-multi-select
          [value]="selectedCustomField1()"
          (updateValue)="handleUpdateCustomField1($event)"
          [options]="customField1Options()"
          selectId="slideType"
          [placeholder]="'FILTER_BAR.ALL' | translate"
          searchable
        />
      </div>
    }
  </section>

  <section class="filter-actions">
    <dpqa-icon iconName="more-horizontal" />
  </section>
</div>
