import { Directive, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserConfigType } from '@tig-dpqa-cloud/contract-backend-frontend';
import { filter } from 'rxjs';
import { UserContextService } from '../../services/user-context/user-context.service';

@Directive({
  selector: '[dpqaRequirePermissions]',
  standalone: true,
})
export class RequirePermissionsDirective {
  private userContext = inject(UserContextService);

  private templateRef = inject(TemplateRef<unknown>);
  private viewContainer = inject(ViewContainerRef);

  public dpqaRequirePermissions = input.required<UserConfigType['permissions']>();

  private userPermissions = this.userContext.userPermissions;

  constructor() {
    toObservable(this.userPermissions)
      .pipe(filter(Boolean))
      .subscribe((permissions) => {
        const hasPermission = this.dpqaRequirePermissions().every((permission) => permissions.includes(permission));
        this.viewContainer.clear();
        if (hasPermission) {
          const view = this.viewContainer.createEmbeddedView(this.templateRef);
          /**
           * This solves an issue where the view was not rendered immediately.
           */
          view.detectChanges();
        }
      });
  }
}
