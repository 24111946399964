import { CommonModule } from '@angular/common';
import { Component, inject, model } from '@angular/core';
import { SlideScanStatus } from '../../../types/slide-scan-status';
import { SelectComponent } from '../../select/select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dpqa-status-picker',
  standalone: true,
  imports: [CommonModule, SelectComponent],
  templateUrl: './status-picker.component.html',
  styleUrl: './status-picker.component.less',
})
export class StatusPickerComponent {
  private translateService = inject(TranslateService);
  status = model.required<SlideScanStatus>();

  statuses: { key: string; value: SlideScanStatus }[] = [
    { key: this.translateService.instant('FILTER_BAR.ALL'), value: 'all' },
    { key: this.translateService.instant('FILTER_BAR.IN_QUEUE'), value: 'in-queue' },
    { key: this.translateService.instant('FILTER_BAR.VERIFIED'), value: 'verified' },
  ];

  handleUpdateValue(e: SlideScanStatus) {
    this.status.set(e);
  }
}
