<button
  nz-button
  class="button"
  [class.default]="type() === 'default'"
  [nzType]="type()"
  [ngStyle]="{
    height: height() + 'rem',
    'font-size': fontSize() + 'rem',
    padding: paddingResult(),
  }"
  [disabled]="disabled()"
  [nzLoading]="loading()"
  [nzDanger]="danger()"
>
  @if (iconName(); as name) {
    <dpqa-icon [iconName]="name" [iconSize]="iconSize()" [disabled]="disabled()"/>
  }
  <ng-content />
</button>
