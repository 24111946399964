import { booleanAttribute, Component, computed, input, numberAttribute } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'dpqa-icon',
  standalone: true,
  imports: [CommonModule, NzIconModule],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.less',
})
export class IconComponent {
  public iconName = input.required<string>();
  public iconSize = input(2.4, { transform: numberAttribute });
  public iconLink = computed(() => `assets/icons/trybe.svg#${this.iconName()}`);
  public disabled = input(false, { transform: booleanAttribute });
}
