<span
  data-testid="icon"
  nz-icon
  [ngStyle]="{
    width: iconSize() + 'rem',
    height: iconSize() + 'rem',
    opacity: disabled() ? 0.2 : 1
  }"
>
  <svg>
    <use [attr.href]="iconLink()" />
  </svg>
</span>
