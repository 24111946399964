import { z } from 'zod';

export const upgradeMessage = z.discriminatedUnion('version', [
  z.object({
    type: z.literal('upgrade'),
    version: z.literal('1'),
    payload: z.object({
      version: z.string().describe('Specific version to upgrade to'),
    }),
  }),
]);

export type UpgradeMessage = z.infer<typeof upgradeMessage>;

export const iotMessage = z.discriminatedUnion('type', [...upgradeMessage.options]);

export type IotMessage = z.infer<typeof iotMessage>;
