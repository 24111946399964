import { AwsRum, type AwsRumConfig } from 'aws-rum-web';
import { createInjectionToken } from 'ngxtension/create-injection-token';
import { UserContextService } from '../user-context/user-context.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';
import { type ExtractedServiceType } from '../../types/extracted-service';

const rumFactory = (userContextService: ExtractedServiceType<typeof UserContextService>) => {
  const config: AwsRumConfig = {
    allowCookies: false,
    sessionSampleRate: 1,
    pageIdFormat: 'PATH',
    sessionAttributes: {
      displayResolution: `${window.screen.width}x${window.screen.height}`,
      zoomLevel: `${Math.round(window.devicePixelRatio * 100)}%`,
    },
    telemetries: [
      ['http', { urlsToInclude: [new RegExp(/.*\/api\/.*/), new RegExp(/\.dzi$/)] }],
      ['performance', { ignore: (entry: PerformanceEntry) => entry.entryType === 'resource' }],
      'error',
    ],
    recordResourceUrl: false,
    disableAutoPageView: true,
  };

  const awsRum$ = toObservable(userContextService.credentials).pipe(
    filter(Boolean),
    map((credentials) => {
      const awsRum = new AwsRum(credentials.appMonitorId, '0.0.0', 'eu-west-1', config);

      awsRum.setAwsCredentials({
        accessKeyId: credentials.accessKey,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      });

      awsRum.enable();

      return awsRum;
    }),
  );

  return {
    awsRum: toSignal(awsRum$),
    cleanup() {
      const rum = this.awsRum();

      if (rum) {
        rum.dispatch();
        rum.disable();
      }
    },
  };
};

export const [injectRumService, provideRumService, RUM_SERVICE] = createInjectionToken(rumFactory, {
  deps: [UserContextService],
});
